<template>
  <FormModal
    title="Insérez vos coordonnées"
    next-btn-text="Comparez maintenant"
    show-back-btn
  >
    <template #body>
      <ContactInformationFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import ContactInformationFormStep from '~/components/form/steps/contactInformation/ContactInformationFormStep'

export default {
  components: {
    ContactInformationFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Vos coordonnées',
      headDescription:
        'Insérez vos coordonnées pour que nous puissions vous mettre en relation avec les spécialistes les plus adaptés dans votre région.',
      path: '/quotes-requests/vos-coordonnees',
      progressValue: 66,
      checkoutStep: 3,
    }
  },
}
</script>
